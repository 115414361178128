import React from 'react';
import { Button } from 'antd';
import { Delivery } from 'src/models/Place';

type Props = {
  delivery: Delivery;
  deliveryMethod: string;
  className?: string;
};

const DeliveryButton = ({ delivery, deliveryMethod, className }: Props) => {
  const getLink = (key: string) => {
    return delivery[key as keyof Delivery]?.link;
  };

  const getImage = (key: string) => {
    switch (key) {
      case 'bitesquad':
        return (
          <img
            alt="BiteSquad"
            width="100px"
            src="https://assets.bitesquad.com/img2017/common/logo_bitesquad_green.svg"
          />
        );
      case 'doordash':
        return (
          <img
            title="DoorDash"
            width="100"
            src="https://upload.wikimedia.org/wikipedia/commons/6/6a/DoorDash_Logo.svg"
          />
        );
      case 'grubhub':
        return (
          <img
            title="GrubHub"
            width="100"
            src="https://assets.grubhub.com/assets/img/grubhub/logo-full-primary.svg"
          />
        );

      case 'ubereats':
        return (
          <img
            alt="Uber Eats"
            width="100"
            src="https://d3i4yxtzktqr9n.cloudfront.net/web-eats-v2/ee037401cb5d31b23cf780808ee4ec1f.svg"
          />
        );

      default:
        break;
    }
  };

  return (
    <Button
      className={className}
      type="default"
      href={getLink(deliveryMethod)}
      target="_blank"
    >
      {getImage(deliveryMethod)}
    </Button>
  );
};

export default DeliveryButton;
