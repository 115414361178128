import React, { ReactNode } from 'react';
import { Tag, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';

import type { IPlace, Delivery, Status } from '@types';

import DeliveryButton from './delivery-button';

import styles from './place-actions.module.less';

const { Text } = Typography;

type ActionGroupProps = {
  children: ReactNode;
};

const ActionsGroup = ({ children }: ActionGroupProps) => (
  <span className={styles.actionsGroup}>{children}</span>
);

type PlaceActionsProps = {
  place: IPlace;
};

const PlaceActions = ({ place }: PlaceActionsProps) => {
  function hasDeliveryOption(key: string) {
    const option = place.delivery
      ? place.delivery[key as keyof Delivery]
      : null;
    return option && !!option.active && !!option.link;
  }

  function isStatusChecked(key: string) {
    return !!(place.status && place.status[key as keyof Status]);
  }

  function getStatusName(key: string) {
    switch (key) {
      case 'delivery':
        return 'Delivery';
      case 'dinein':
        return 'Dine In';
      case 'pickup':
        return 'Pickup';
      default:
        break;
    }
  }

  const Status = () => {
    if (place.status?.__typename) delete place.status.__typename;

    return (
      <>
        {place.status && (
          <ActionsGroup>
            {Object.keys(place.status).map(
              (key) =>
                place.status && (
                  <Tag
                    key={key}
                    icon={
                      isStatusChecked(key) ? (
                        <FontAwesomeIcon
                          size="1x"
                          className="tag-icon"
                          color="green"
                          icon={faCheck}
                        />
                      ) : (
                        <FontAwesomeIcon
                          size="1x"
                          className="tag-icon"
                          color="red"
                          icon={faTimes}
                        />
                      )
                    }
                  >
                    {getStatusName(key)}
                  </Tag>
                )
            )}
          </ActionsGroup>
        )}
      </>
    );
  };

  // const Reservations = () => (
  //   <>
  //     {place.reservations && (
  //       <ActionsGroup>
  //         <Text className={styles.label}>Reservations</Text>
  //         <Tag key="reservations" color={place.reservations ? 'green' : 'red'}>
  //           {place.reservations ? 'Yes' : 'No'}
  //         </Tag>
  //       </ActionsGroup>
  //     )}{' '}
  //   </>
  // );

  const Delivery = () => (
    <>
      {place.delivery && (
        <ActionsGroup>
          <Text className={styles.label}>Order with</Text>
          {Object.keys(place.delivery).map(
            (key) =>
              place.delivery &&
              hasDeliveryOption(key) && (
                <DeliveryButton
                  key={key}
                  deliveryMethod={key}
                  delivery={place.delivery}
                  className={styles.button}
                />
              )
          )}
        </ActionsGroup>
      )}
    </>
  );

  return (
    <div className={styles.actions}>
      <Status />
      <Delivery />
    </div>
  );
};

export default PlaceActions;
