import React from 'react';
import Img from 'gatsby-image';
import moment from 'moment/moment.js';
import { Card } from 'antd';
import { IMenu } from '@types';

type Props = {
  menu: IMenu;
};

const MenuCard = ({ menu }: Props) => {
  return (
    <Card
      cover={
        menu.image ? (
          <Img
            fluid={menu.image.asset.fluid}
            durationFadeIn={1000}
            backgroundColor="black"
          />
        ) : (
          <img src="https://via.placeholder.com/400x300.png/1e2225?text=Image+Not+Available" />
        )
      }
    >
      <div>
        <h3>{menu.name}</h3>
        <h5>{`${moment(menu.startsAt).format('LT')} - ${moment(
          menu.endsAt
        ).format('LT')}`}</h5>
        <h6>{menu.days?.toString().replace(/,/g, ' ')}</h6>
      </div>
    </Card>
  );
};

export default MenuCard;
