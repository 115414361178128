import React, { useState, useCallback, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import { List, Row, Col, Button, Drawer } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { merge } from 'lodash';
import withSizes from 'react-sizes';

import client from '@lib/sanity';
import PLACE_QUERY from '@queries/sanity/update-food-place.gql';
import { watchPlaceQuery } from '@queries/sanity/food-place.groq';

import SEO from '@layout/seo';
import { Page, Section } from '@layout/page';
import Headline from '@layout/headline';
import PageBreadcrumb from '@layout/page-breadcrumb';

import PlaceActions from '@components/place/place-actions';
import MenuCard from '@components/menu/menu-card';

import type { IPlace, IMenu, IFollow, IStore } from '@types';

import styles from './place.module.less';
import { useAuth } from '@providers/auth-provider';
import { isFollowed } from '@providers/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { follow, unfollow, fetchFollowsByAccount } from '@providers/actions';
import Subscribe from '@components/subscribe';

type Props = {
  data: {
    place: IPlace;
  };
  isMobile?: boolean;
};

export const pageQuery = graphql`
  query Place($placeId: String) {
    place: sanityFoodPlace(id: { eq: $placeId }) {
      ...placeFields
    }
  }
`;

const PlacePage = ({ data, isMobile }: Props) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const [place, setPlace] = useState(data.place);
  const [showSubscribe, setShowSubscribe] = useState(false);
  const follows = useSelector((state: IStore) => state.follows);
  const [isPlaceFollowed, setIsPlaceFollowed] = useState(false);
  const [followLabel, setFollowLabel] = useState('Follow');

  const routes = [
    {
      path: `/`,
      breadcrumbName: `Home`,
      icon: 'home',
    },
    {
      path: `/places/`,
      breadcrumbName: `Places`,
      icon: 'location',
    },
    {
      path: `/places/${place.slug.current}/`,
      breadcrumbName: place.name,
      icon: 'dish',
    },
  ];

  const update = (data: IPlace) => {
    return {
      ...place,
      ...data,
      menus: merge(place.menus, data.menus),
    };
  };

  const getMenuIds = () =>
    place.menus.map((menu) => {
      return menu._id;
    });

  const watch = () =>
    client
      .listen(
        watchPlaceQuery,
        {
          placeId: place._id,
          menuIds: getMenuIds(),
        },
        { includeResult: false }
      )
      .subscribe(refresh);

  const refresh = () =>
    setTimeout(
      () =>
        refetch().then((res) => {
          setPlace(update(res.data.place));
        }),
      500
    );

  const { refetch } = useQuery(PLACE_QUERY, {
    variables: {
      id: place._id,
    },
    skip: !place._id,
    onCompleted: (res) => {
      setPlace(update(res.place));
      watch();
    },
    fetchPolicy: 'no-cache',
  });

  const onFollowPlace = () => {
    setShowSubscribe(!isAuthenticated);
    if (isAuthenticated) {
      isPlaceFollowed ? dispatch(unfollow(place)) : dispatch(follow(place));
    }
  };

  const fetchFollows = useCallback(() => {
    dispatch(fetchFollowsByAccount(place.account._id));
  }, [dispatch, fetchFollowsByAccount]);

  const onClose = () => setShowSubscribe(false);

  const getWidth = () => (isMobile ? '100%' : '50%');

  const isFollowed = (id: string) =>
    follows.some(
      (follow: IFollow) => follow.externalId === id && follow.followed === true
    );

  const checkIsPlaceFollowed = useCallback(() => {
    place._id && setIsPlaceFollowed(isFollowed(place._id));
    isPlaceFollowed ? setFollowLabel('Unfollow') : setFollowLabel('Follow');
  }, [place, isFollowed]);

  useEffect(() => {
    if (isAuthenticated) fetchFollows();
  }, [fetchFollows, isAuthenticated]);

  useEffect(() => {
    checkIsPlaceFollowed();
  }, [checkIsPlaceFollowed]);

  return (
    <Page id="place-page" color="white" className={styles.place}>
      <SEO
        title={`${place?.name} - ${place?.address?.city}, ${place?.address?.state}`}
      />
      <Section>
        <PageBreadcrumb routes={routes} color="light" />
        <Row align="middle">
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Headline
              className="no-padding"
              background="white"
              lead={place.cuisine}
              emphasis={place.name}
              subtitle={place.headline}
            />
          </Col>
          <Col className="align-right" xs={24} sm={24} md={24} lg={12} xl={12}>
            <Button
              type="primary"
              color="green"
              size="large"
              className={styles.followBtn}
              onClick={onFollowPlace}
            >
              {followLabel}
            </Button>
          </Col>
        </Row>
        <PlaceActions place={place} />
        <List
          size="large"
          grid={{
            gutter: 24,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
          dataSource={place?.menus}
          renderItem={(menu: IMenu) => {
            return (
              <List.Item className={(styles.cards, 'black')} key={menu.id}>
                <Link
                  to={`/places/${place?.slug?.current}/menus/${menu?.slug?.current}`}
                >
                  <MenuCard menu={menu} />
                </Link>
              </List.Item>
            );
          }}
        />
      </Section>
      <Drawer
        placement="right"
        className={styles.drawer}
        closable={true}
        onClose={onClose}
        visible={showSubscribe}
        width={getWidth()}
      >
        <Subscribe />
      </Drawer>
    </Page>
  );
};

const mapSizesToProps = ({ width }: { width: number }) => ({
  isMobile: width < 800,
});

export default withSizes(mapSizesToProps)(PlacePage);
